@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F7FE;
  overflow-x: hidden !important;
}
p{
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e9e8e8; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b4b3b3; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9b9a9a; 
}
.navDropdown > .dropdown-menu {
  right: 50%;
  left: auto;
}
