.navbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: #FFFFFF;
}
.hideSm{
    display: block;
}
.panleSlider{
    display: flex;
    gap: 10px;
    overflow-x: auto;
}
.panelBtn{
    font-weight: 500;
    color: #838383;
    background-color: #FFFFFF;
    border-radius:15px;
    text-wrap: nowrap;
    margin: 10px 0;
    font-size: 12px;
    transition: 0.8s;
}
.panelBtnActive{
    font-weight: 500;
    color: #FFFFFF;
    background-color: #26656A;
    border-radius:15px;
    text-wrap: nowrap;
    margin: 10px 0;
    font-size: 12px;
    transition: 0.8s; 
} 
.panelBtn:hover{
    color:#FFFFFF;
    background-color: #26656A;
    transition: 0.8s;  
} 
.panelBtnActive:hover{
    color:#FFFFFF;
    background-color: #26656A;
    transition: 0.8s;  
}
.prSiteData{
    height: 350px;
    overflow: hidden;
    position: relative;
    transition: 0.5s;
}
.prSiteDataAll{
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: 0.5s; 
}
.showMore{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
    background-image: url('../images/seeMoreBg.png');
    background-size: 100% 100%; 
}

tbody tr {
    margin-top: 2px !important;
}

@media(max-width:767px){
    .hideSm{
        display: none; 
        transition: 2s;
    } 
}